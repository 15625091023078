import React, { useState, useEffect } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/header';
import Landing from './components/landingpage';
import Learn from './components/learn';
import Footer from './components/footer';
import Preloader from './components/preloader'; // Import the Preloader component

const App = () => {
    const [isLoading, setIsLoading] = useState(true);

    // Let create async method to fetch fake data
    useEffect(() => {
      const fakeDataFetch = () => {
        setTimeout(() => {
          setIsLoading(false);
        }, 3000);
      };
  
      fakeDataFetch();
    }, []);
  
    return isLoading ? (
        <Preloader />
        
    ) : (
        <Router>
            <Header />
            <Routes>
                <Route path="/" element={<Landing />} />
                <Route path="/home" element={<Landing />} />
                <Route path="/home/*" element={<Landing />} />
                <Route path="/home/learn/:projectId" element={<Learn />} />
            </Routes>
            <Footer />
        </Router>
    );
};

export default App;
