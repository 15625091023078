import React, { useState, useEffect } from "react";


const Preloader = () => {
  const [loading, setLoading] = useState(true);
  const [dots, setDots] = useState("");

  useEffect(() => {
    const timer = setInterval(() => {
      setDots((prevDots) => {
        return prevDots.length >= 3 ? "" : prevDots + ".";
      });
    }, 500);

    setTimeout(() => {
      clearInterval(timer);
      setLoading(false);
    }, 3000); // Change the duration as needed

    // Cleanup function to clear the interval when the component unmounts or reloads
    return () => {
      clearInterval(timer);
    };
  }, []);

  // Set loading state to true when the component mounts or reloads
  useEffect(() => {
    setLoading(true);
  }, []);

  return (
    <div className="loader">
      <div className="svg-wrapper">
        {/* Image */}
        {/* <img
          src="https://res.cloudinary.com/dbxcfrxhf/image/upload/f_auto,q_auto/logo"
          alt="Image"
          style={{ width: "200px", height: "180px" }}
        /> */}
        {/* Loading text with moving dots */}
        <div className="loading-text">"Explore Living"-KAON{dots}</div>
      </div>
    </div>
  );
};

export default Preloader;
