import React, { useRef, useState, useEffect } from 'react';
import emailjs from '@emailjs/browser';
import ReCAPTCHA from 'react-google-recaptcha'; // Import reCAPTCHA
import axios from 'axios'; // Import axios for making HTTP requests


const Contacts = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const form = useRef();
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isAlertOpen, setAlertOpen] = useState(false);
  const [isErrorOpen, setErrorOpen] = useState(false);
  const [isWarningOpen, setWarningOpen] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [showEmailExistsMessage, setShowEmailExistsMessage] = useState(false);
  const [isInvalidEmail, setIsInvalidEmail] = useState(false);
  const [isRobotVerified, setIsRobotVerified] = useState(false); // Track robot verification

  const RECAPTCHA_SITE_KEY = '6LcBoH0pAAAAAGsfF0P4dbrU2_RVrUMpufwuQ8Sv'; // Replace with your reCAPTCHA site key

  useEffect(() => {
    // Check if the email address has been submitted before
    const submittedEmails = localStorage.getItem('submittedEmails');
    if (submittedEmails) {
      const parsedSubmittedEmails = JSON.parse(submittedEmails);
      const currentEmail = form.current['user_email'].value.trim();
      if (parsedSubmittedEmails.includes(currentEmail)) {
        setIsFormSubmitted(true);
        setIsButtonDisabled(true); // Disable the button if email is already submitted
        setShowEmailExistsMessage(true); // Show email exists message
      } else {
        setIsFormSubmitted(false); // Reset form submission state
        setIsButtonDisabled(false); // Enable the button if email is not submitted
        setShowEmailExistsMessage(false); // Hide email exists message
      }
    }
  }, []);

  useEffect(() => {
    const handleInputChange = () => {
      // Your input change handling logic
    };

    const inputElement = form.current.querySelector('input[name="user_email"]');
    if (inputElement) {
      inputElement.addEventListener('input', handleInputChange);
    }

    // Clean up event listener
    return () => {
      if (inputElement) {
        inputElement.removeEventListener('input', handleInputChange);
      }
    };
  }, [form]); // Include form in the dependencies array if it's a prop or state variable

  const handleRecaptchaChange = (value) => {
    setIsRobotVerified(true); // Set robot verification status
  };

  const sendEmail = (e) => {
    e.preventDefault();

    if (!isRobotVerified) {
      alert('Please verify that you are not a robot.'); // Display an alert if the user hasn't completed reCAPTCHA
      return;
    }

    // Validate email format
    const email = form.current['user_email'].value.trim(); // Trim whitespace
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(email)) {
      setIsInvalidEmail(true);
      return;
    }

    // Get name and message from the form
    const name = form.current['user_name'].value.trim();
    const message = form.current['message'].value.trim();

    // Perform DNS validation
    const domain = email.split('@')[1]; // Extract domain from email
    fetch(`https://dns.google/resolve?name=${domain}&type=MX`)
      .then(response => response.json())
      .then(data => {
        const mxRecords = data.Answer.filter(record => record.type === 15); // Filter MX records
        if (mxRecords.length === 0) {
          // No MX record found, domain is invalid
          alert('Invalid email domain. Please enter a valid email address.');
        } else {
          // If email is valid, check if it exists in the database
          fetch('https://kaonlinellc.com/api/merri', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email: email }),
          })
            .then(response => response.json())
            .then(data => {
              if (data.exists) {
                setErrorOpen(true);
                setTimeout(() => setErrorOpen(false), 4000);
              } else {
                // If email does not exist in the database, proceed to send the email
                emailjs
                  .sendForm('service_g4zclbt', 'template_r5qugth', form.current, {
                    publicKey: '4rXpcueM_D0kWcSPL',
                  })
                  .then(() => {
                    // Save email, name, and message to the API endpoint
                    fetch('https://kaonlinellc.com/api/ruaj', {
                      method: 'POST',
                      headers: {
                        'Content-Type': 'application/json',
                      },
                      body: JSON.stringify({
                        user_name: name,
                        user_email: email,
                        message: message
                      }),
                    })
                      .then(response => {
                        if (response.ok) {
                          setIsEmailSent(true);
                          clearForm();
                          closeModal();
                          setAlertOpen(true);
                          setTimeout(() => setAlertOpen(false), 3000);
                          setIsFormSubmitted(true); // Set form submission state to true
                          setIsButtonDisabled(true); // Disable the button after successful submission
                        } else {
                          throw new Error('Failed to save email.');
                        }
                      })
                      .catch(error => {
                        // console.error('Error saving email:', error);
                        alert('Error saving email. Please try again later.');
                      });
                  })
                  .catch(error => {
                    alert('Error Please Try again later');
                  });
              }
            })
            .catch(error => {
              // console.error('Error checking email:', error);
              alert('Error checking email. Please try again later.');
            });
        }
      })
      .catch(error => {
        // console.error('Error fetching MX records:', error);
        setWarningOpen(true);
        setTimeout(() => setWarningOpen(false), 3000);
      });
  };

  const clearForm = () => {
    form.current.reset();
  };

  const closeModal = () => {
    setModalOpen(false);
  };
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (isMenuOpen && !event.target.closest('#menu')) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [isMenuOpen]);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };
  return (
    <section className="contacts" id="contacts-section">
      <a id="anchor5" className="anchor"></a>
      <h2 className="wow fadeInUp" style={{ visibility: 'visible', animationName: 'fadeInUp' }}>
        <font style={{ verticalAlign: 'inherit' }}>
          <font style={{ verticalAlign: 'inherit' }}>Contacts</font>
        </font>
      </h2>
      <div className="contacts-top">
        <div className="contacts-adress wow fadeInUp" style={{ visibility: 'visible', animationName: 'fadeInUp' }}>
          <div className="contacts-adress__item">
            <span className="title">
              <font style={{ verticalAlign: 'inherit' }}>
                <font style={{ verticalAlign: 'inherit' }}>Location:</font>
              </font>
            </span>
            <span className="text">
              <font style={{ verticalAlign: 'inherit' }}>
                <font style={{ verticalAlign: 'inherit' }}>Str. Muharrem Fejza, Pristina</font>
              </font>
            </span>
          </div>
          <div className="contacts-adress__item">
            <span className="title">
              <font style={{ verticalAlign: 'inherit' }}>
                <font style={{ verticalAlign: 'inherit' }}>Working hours:</font>
              </font>
            </span>
            <span className="text">
              <font style={{ verticalAlign: 'inherit' }}>
                <font style={{ verticalAlign: 'inherit' }}>Mon-Fri 09:00-17:00, Sat 09:00-14:00</font>
              </font>
            </span>
          </div>
          <div className="contacts-adress__item">
            <span className="title">
              <font style={{ verticalAlign: 'inherit' }}>
                <font style={{ verticalAlign: 'inherit' }}>E-mail:</font>
              </font>
            </span>
            <span className="text">
              <font style={{ verticalAlign: 'inherit' }}>
                <font style={{ verticalAlign: 'inherit' }}>kaonlinellc@gmail.com</font>
              </font>
            </span>
          </div>
        </div>
        <div className="contacts-phone wow fadeInUp pointer" style={{ visibility: 'visible', animationName: 'fadeInUp' }}>
          <span className="phone-item">
            <i className="fa fa-phone"></i>
            <font style={{ verticalAlign: 'inherit' }}>
              <font style={{ verticalAlign: 'inherit' }}>+38344 238 024</font>
            </font>
          </span>
          <span className="arrow-before header__link sat_link_call hire" onClick={() => setModalOpen(true)}>Hire us</span>
        </div>
      </div>
      <div className={`modal ${isModalOpen ? 'show' : ''}`} id="contactModal">
        <div className="fancybox-stage">
          <div
            className="fancybox-slide fancybox-slide--html fancybox-slide--current fancybox-slide--complete">
            <div className="sat_link_call_popup fancybox-content" style={{ display: "inline-block" }}>
              <div id="content">
                {isFormSubmitted ? (
                  <div className="feedback-content">
                    <div className="h2">Thank You!</div>
                    <p>Your message has been sent successfully.</p>
                  </div>
                ) : (
                  <div className="feedback-content">
                    {showEmailExistsMessage && (
                      <p>You have already submitted your email. We will contact you soon.</p>
                    )}
                    {!showEmailExistsMessage && (
                      <>
                        <p>Leave your information details<br />
                          and we will contact you shortly.</p>
                        <form ref={form} onSubmit={sendEmail}>
                          <div className="input-wrapper">
                            <input type="text" required placeholder="Name" id="user_name" name="user_name" />
                          </div>
                          <div className="input-wrapper">
                            <input type="email" required placeholder="Email" id="user_email" name="user_email" />
                          </div>
                          <div className="input-wrapper">
                            <textarea required placeholder="Message" id="message" name="message" ></textarea>
                          </div>
                          <ReCAPTCHA
                            sitekey={RECAPTCHA_SITE_KEY}
                            onChange={handleRecaptchaChange}
                          />
                          <input type="submit" value="Send" className="levizja feedback-content__submit" disabled={isButtonDisabled} />
                        </form>
                      </>
                    )}
                  </div>
                )}
              </div>
              <button type="button" className="fancybox-button fancybox-close-small close" onClick={closeModal} aria-label="Close">
                <span className="x-icon">&#10006;</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      {isAlertOpen && (
        <div className="custom-alert1">
          <div className="alert-content">
            Thank you for reaching out! Your message has been successfully sent.
          </div>
        </div>
      )}

      {isErrorOpen && (
        <div className="custom-alert1">
          <div className="alert-content">
            It seems you've already submitted a message with this email address. We appreciate your interest and will get back to you shortly.
          </div>
        </div>
      )}

      {isWarningOpen && (
        <div className="custom-alert2">
          <div className="alert-content">
            The email address you provided is not valid. Please double-check and try again later.
          </div>
        </div>
      )}
    </section>
  );
};

export default Contacts;
