import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { handleScrollAnimation } from '../test';
import Services from './services';
import About from './aboutus';
import Contacts from './contacts';

const LandingPage = () => {
    const [projects, setProjects] = useState([]);
    const [displayedProjects, setDisplayedProjects] = useState(6);

    useEffect(() => {
        fetchProjects();
        handleScrollOnLoad();
        handleSmoothScrollForHashLinks();
        handleScrollAnimation();
    }, []);

    const fetchProjects = () => {
        fetch(`https://kaonlinellc.com/api/projects`)
            .then(response => response.json())
            .then(data => setProjects(data || []))
            .catch(error => console.error('Error fetching projects:', error));
    };

    const handleLoadMore = () => {
        if (displayedProjects < projects.length) {
            setDisplayedProjects(prevDisplayedProjects => prevDisplayedProjects + 3);
        } else {
            console.log("All projects are displayed");
        }
    };

    const handleScrollOnLoad = () => {
        if (window.location.hash) {
            const sectionId = window.location.hash.substring(1);
            scrollToSection(sectionId);
        }
    };

    const handleSmoothScrollForHashLinks = () => {
        document.querySelectorAll('a[href^="#"]').forEach(anchor => {
            anchor.addEventListener('click', function (e) {
                e.preventDefault();
                const targetId = this.getAttribute('href');
                const target = document.querySelector(targetId);
                if (target) {
                    target.scrollIntoView({ behavior: 'smooth', block: 'start' });
                }
            });
        });
    };

    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div>
            <section className="main-block" id="home-section">
                <div className="gallery">
                    <div className="image-container">
                        <img src="https://res.cloudinary.com/dbxcfrxhf/image/upload/f_auto,q_auto/D" alt="Your Image" />
                        <div className="promo wow fadeInUp" style={{ visibility: 'visible', animationName: 'fadeInUp' }}>
                            <h1>Interior Design</h1>
                            <small>and Architecture since 2022</small>
                        </div>
                    </div>
                    <div className="image-container">
                        <img src="https://res.cloudinary.com/dbxcfrxhf/image/upload/f_auto,q_auto/c" alt="Image 2" />
                    </div>
                    <div className="image-container">
                        <img src="https://res.cloudinary.com/dbxcfrxhf/image/upload/f_auto,q_auto/V3" alt="Image 3" />
                    </div>
                </div>
            </section>


            <section className="infographics">
                <div className="infographic-item" data-wow-duration="1s" data-wow-delay="0s"
                    style={{ visibility: 'visible', animationDuration: '2s', animationDelay: '0s', animationName: 'fadeInUp' }}>
                    <span className="infographic-item__title">
                        <font style={{ verticalAlign: 'inherit' }}>
                            <font style={{ verticalAlign: 'inherit' }}>+</font>
                        </font>
                        <span className="infographics__number" data-count="15">0</span>
                        <small>
                            <font style={{ verticalAlign: 'inherit' }}>
                                <font style={{ verticalAlign: 'inherit' }}>Interior Designs</font>
                            </font>
                        </small>
                    </span>
                </div>
                <div className="infographic-item" data-wow-duration="1s" data-wow-delay="0s"
                    style={{ visibility: 'visible', animationDuration: '2s', animationDelay: '0s', animationName: 'fadeInUp' }}>
                    <span className="infographic-item__title">
                        <font style={{ verticalAlign: 'inherit' }}>
                            <font style={{ verticalAlign: 'inherit' }}>+</font>
                        </font>
                        <span className="infographics__number" data-count="3">0</span>
                        <small>
                            <font style={{ verticalAlign: 'inherit' }}>
                                <font style={{ verticalAlign: 'inherit' }}>Architecture Designs</font>
                            </font>
                        </small>
                    </span>
                </div>
                <div className="infographic-item" data-wow-duration="1s" data-wow-delay="0.6s"
                    style={{ visibility: 'visible', animationDuration: '1s', animationDelay: '0.6s', animationName: 'fadeInUp' }}>
                    <span className="infographic-item__title">
                        <font style={{ verticalAlign: 'inherit' }}>
                            <font style={{ verticalAlign: 'inherit' }}>+</font>
                        </font>
                        <span className="infographics__number" data-count="18">0</span>
                        <small>
                            <font style={{ verticalAlign: 'inherit' }}>
                                <font style={{ verticalAlign: 'inherit' }}>Implemented objects</font>
                            </font>
                        </small>
                    </span>
                </div>
                <div className="infographic-item" data-wow-duration="1s" data-wow-delay="0.9s"
                    style={{ visibility: 'visible', animationDuration: '1s', animationDelay: '0.9s', animationName: 'fadeInUp' }}>
                    <span className="infographic-item__title">
                        <font style={{ verticalAlign: 'inherit' }}>
                            <font style={{ verticalAlign: 'inherit' }}>+</font>
                        </font>
                        <span className="infographics__number" data-count="11">0</span>
                        <small>
                            <font style={{ verticalAlign: 'inherit' }}>
                                <font style={{ verticalAlign: 'inherit' }}>Years of experience</font>
                            </font>
                        </small>
                    </span>
                </div>
            </section>
            <About />


            <section className="projects" id="projects-section">
                <a id="anchor2" className="anchor"></a>
                <h2 className="wow fadeInUp" style={{ visibility: 'visible', animationName: 'fadeInUp' }}>
                    <span>
                        Our projects
                    </span>
                </h2>
                <div id="add_project" className="project-items">
                {projects && projects.slice(0, displayedProjects).map(project => (
                        <div className="project-item wow fadeInUp" style={{ visibility: 'visible', animationName: 'fadeInUp' }} key={project.id}>
                            <div className="project-item__image">
                                <img src={`https://res.cloudinary.com/dbxcfrxhf/image/upload/f_auto,q_auto/${project.photo}`} alt="Photos" />
                                <div className="project-item__info">
                                    <div className="border">
                                        <div className="project-content">
                                            <span className="project-item__title">{project.title}</span>
                                            <span className="project-item__city">{project.city}</span>
                                            <p>{project.description}</p>
                                            <div className="project-bottom">
                                                <Link to={`/home/learn/${project.id}`}>Learn More</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <span className="project-item__title">{project.title}</span>
                            <span className="project-item__city">{project.city}</span>
                        </div>
                    ))}
                </div>
            </section>
            {projects.length > displayedProjects && (
                <button id="load" className="load-more-btn load-more" onClick={handleLoadMore}>
                    Load More
                </button>
            )}
            <Services />
            {/* <Map /> */}
            <Contacts />


        </div>

    );
};

export default LandingPage;

