import React, { useState, useEffect } from 'react';
import ArrowIcon from './images/arrow.png'; // Import your SVG file

function ScrollToTopArrow() {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        // Show/hide the scroll-to-top arrow based on scroll position
        const toggleVisibility = () => {
            if (window.pageYOffset > 100) {
                setIsVisible(true);
                document.body.classList.add('hide-scrollbar');
            } else {
                setIsVisible(false);
                document.body.classList.remove('hide-scrollbar');
            }
        };

        window.addEventListener('scroll', toggleVisibility);

        return () => {
            window.removeEventListener('scroll', toggleVisibility);
        };
    }, []);

    const scrollToTop = () => {
        document.body.style.overflowY = 'auto'; // Show scrollbar when scrolling to top

        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    return (
        <div>
            {isVisible && (
                <div className="scroll-to-top" onClick={scrollToTop}>
                    <img src={ArrowIcon} alt="Scroll to top" />
                </div>
            )}
        </div>
    );
}

export default ScrollToTopArrow;
