import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import '../App.css';

import axios from 'axios'; // Import axios library


const Learn = () => {
    const { projectId } = useParams();
    const [learn, setLearn] = useState([]);
    const [displayedLearn, setDisplayedLearn] = useState(6);

    useEffect(() => {
        fetchProjectDetails(projectId);
    }, [projectId]);


    const fetchProjectDetails = (id) => {
        fetch(`https://kaonlinellc.com/api/learn/${projectId}`)
            .then(response => response.json())
            .then(data => {
                const dataArray = Array.isArray(data) ? data : (data ? [data] : []);
                setLearn(dataArray);
            })
            .catch(error => console.error('Error fetching project details:', error));
    };

    const renderDescriptionWithLineBreaks = (description) => {
        // Split the description by commas and map over each part
        return description.split(',').map((part, index, array) => (
            <React.Fragment key={index}>
                <span className="description-part">{part.trim()}</span> {/* Render each part and trim any whitespace */}
                {index !== array.length - 1 && <br />} {/* Add <br> except for the last part */}
            </React.Fragment>
        ));
    };
    return (
        <>
             {learn && learn.slice(0, displayedLearn).map(learn => (
                <div className="project-page" key={learn.id}>
                    <div className="section">
                        <div className="project-page__first-screen" style={{ backgroundImage: `url(https://res.cloudinary.com/dbxcfrxhf/image/upload/f_auto,q_auto/${learn.background})` }}>
                            <div className="container1">
                                <div className="project-page__first-screen__content">
                                    <div className="project-page__first-screen__location text-uppercase h4">
                                        Kaon Line<br />
                                        © 2022
                                    </div>
                                    <div className="project-page__first-screen__title flex align-end justify-between">
                                        {/* <div className="project-page__first-screen__name text1">
                                            {learn.description}
                                        </div> */}
                                        <a href="#about-block" className="link text-uppercase">
                                            Scroll to <b>explore</b>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="section" id="about-block">
                            <div className="project-page__inner">
                                <div className="container1">
                                    <div className="project-page__title h3 wow fadeInUp">
                                        <span className="project-page__title__notice h5 text-uppercase">Description</span>
                                        <span className="project-page__title__top">{renderDescriptionWithLineBreaks(learn.description)}</span>
                                    </div>

                                    <div className="project-page__descr flex justify-between wow fadeInUp">
                                        <div className="project-page__descr__left">
                                            <div className="project-page__descr__left__item">
                                                <div className="fz-12 member-status">Location:</div>
                                                <div className="member-name h5 text-uppercase">
                                                    <span>{learn.location}</span>
                                                </div>
                                            </div>
                                            <div className="project-page__descr__left__item" style={{ visibility: 'visible', animationDelay: '0.4s', animationName: 'fadeInUp' }}>
                                                <div className="fz-12 member-status">
                                                    Designers:
                                                </div>
                                                <div className="member-name h5 text-uppercase">
                                                    <span>{learn.designers}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="project-page__descr__right">
                                            <p>{learn.text}</p>
                                        </div>
                                    </div>

                                    <div className="project-page__photos">
                                        {learn.foto1 && (
                                            <div className="project-page__photos__item wow fadeInUp">
                                                <img src={`https://res.cloudinary.com/dbxcfrxhf/image/upload/f_auto,q_auto/${learn.foto1}`} alt="" />
                                            </div>
                                        )}

                                        {learn.foto2 && learn.foto3 && (
                                            <div className="project-page__photos__item wow fadeInUp new_sat_prof_nf">
                                                <div className="project-page__photos__item__two-photos">
                                                    <img src={`https://res.cloudinary.com/dbxcfrxhf/image/upload/f_auto,q_auto/${learn.foto2}`} alt="" />
                                                    <img src={`https://res.cloudinary.com/dbxcfrxhf/image/upload/f_auto,q_auto/${learn.foto3}`} alt="" />
                                                </div>
                                            </div>
                                        )}

                                        {learn.foto4 && learn.foto5 && (
                                            <div className="project-page__photos__item wow fadeInUp new_sat_prof_nf">
                                                <div className="project-page__photos__item__two-photos">
                                                    <img src={`https://res.cloudinary.com/dbxcfrxhf/image/upload/f_auto,q_auto/${learn.foto4}`} alt="" />
                                                    <img src={`https://res.cloudinary.com/dbxcfrxhf/image/upload/f_auto,q_auto/${learn.foto5}`} alt="" />
                                                </div>
                                            </div>
                                        )}
                                        {learn.foto6 && (
                                            <div className="project-page__photos__item wow fadeInUp">
                                                <img src={`https://res.cloudinary.com/dbxcfrxhf/image/upload/f_auto,q_auto/${learn.foto6}`} alt="" />
                                            </div>
                                        )}
                                        {learn.foto7 && learn.foto8 && (
                                            <div className="project-page__photos__item wow fadeInUp new_sat_prof_nf">
                                                <div className="project-page__photos__item__two-photos">
                                                    <img src={`https://res.cloudinary.com/dbxcfrxhf/image/upload/f_auto,q_auto/${learn.foto7}`} alt="" />
                                                    <img src={`https://res.cloudinary.com/dbxcfrxhf/image/upload/f_auto,q_auto/${learn.foto8}`} alt="" />
                                                </div>
                                            </div>
                                        )}
                                        {learn.foto9 && learn.foto10 && (
                                            <div className="project-page__photos__item wow fadeInUp new_sat_prof_nf">
                                                <div className="project-page__photos__item__two-photos">
                                                    <img src={`https://res.cloudinary.com/dbxcfrxhf/image/upload/f_auto,q_auto/${learn.foto9}`} alt="" />
                                                    <img src={`https://res.cloudinary.com/dbxcfrxhf/image/upload/f_auto,q_auto/${learn.foto10}`} alt="" />
                                                </div>
                                            </div>
                                        )}
                                        {learn.foto11 && learn.foto12 && (
                                            <div className="project-page__photos__item wow fadeInUp new_sat_prof_nf">
                                                <div className="project-page__photos__item__two-photos">
                                                    <img src={`https://res.cloudinary.com/dbxcfrxhf/image/upload/f_auto,q_auto/${learn.foto11}`} alt="" />
                                                    <img src={`https://res.cloudinary.com/dbxcfrxhf/image/upload/f_auto,q_auto/${learn.foto12}`} alt="" />
                                                </div>
                                            </div>
                                        )}
                                        {learn.foto13 && learn.foto14 && (
                                            <div className="project-page__photos__item wow fadeInUp new_sat_prof_nf">
                                                <div className="project-page__photos__item__two-photos">
                                                    <img src={`https://res.cloudinary.com/dbxcfrxhf/image/upload/f_auto,q_auto/${learn.foto13}`} alt="" />
                                                    <img src={`https://res.cloudinary.com/dbxcfrxhf/image/upload/f_auto,q_auto/${learn.foto14}`} alt="" />
                                                </div>
                                            </div>
                                        )}
                                        {learn.foto15 && learn.foto16 && (
                                            <div className="project-page__photos__item wow fadeInUp new_sat_prof_nf">
                                                <div className="project-page__photos__item__two-photos">
                                                    <img src={`https://res.cloudinary.com/dbxcfrxhf/image/upload/f_auto,q_auto/${learn.foto15}`} alt="" />
                                                    <img src={`https://res.cloudinary.com/dbxcfrxhf/image/upload/f_auto,q_auto/${learn.foto16}`} alt="" />
                                                </div>
                                            </div>
                                        )}
                                        {learn.foto17 && learn.foto18 && (
                                            <div className="project-page__photos__item wow fadeInUp new_sat_prof_nf">
                                                <div className="project-page__photos__item__two-photos">
                                                    <img src={`https://res.cloudinary.com/dbxcfrxhf/image/upload/f_auto,q_auto/${learn.foto17}`} alt="" />
                                                    <img src={`https://res.cloudinary.com/dbxcfrxhf/image/upload/f_auto,q_auto/${learn.foto18}`} alt="" />
                                                </div>
                                            </div>
                                        )}
                                        {learn.foto19 && learn.foto20 && (
                                            <div className="project-page__photos__item wow fadeInUp new_sat_prof_nf">
                                                <div className="project-page__photos__item__two-photos">
                                                    <img src={`https://res.cloudinary.com/dbxcfrxhf/image/upload/f_auto,q_auto/${learn.foto19}`} alt="" />
                                                    <img src={`https://res.cloudinary.com/dbxcfrxhf/image/upload/f_auto,q_auto/${learn.foto20}`} alt="" />
                                                </div>
                                            </div>
                                        )}
                                        {learn.foto21 && learn.foto22 && (
                                            <div className="project-page__photos__item wow fadeInUp new_sat_prof_nf">
                                                <div className="project-page__photos__item__two-photos">
                                                    <img src={`https://res.cloudinary.com/dbxcfrxhf/image/upload/f_auto,q_auto/${learn.foto21}`} alt="" />
                                                    <img src={`https://res.cloudinary.com/dbxcfrxhf/image/upload/f_auto,q_auto/${learn.foto22}`} alt="" />
                                                </div>
                                            </div>
                                        )}
                                        {learn.foto23 && learn.foto24 && (
                                            <div className="project-page__photos__item wow fadeInUp new_sat_prof_nf">
                                                <div className="project-page__photos__item__two-photos">
                                                    <img src={`https://res.cloudinary.com/dbxcfrxhf/image/upload/f_auto,q_auto/${learn.foto23}`} alt="" />
                                                    <img src={`https://res.cloudinary.com/dbxcfrxhf/image/upload/f_auto,q_auto/${learn.foto24}`} alt="" />
                                                </div>
                                            </div>
                                        )}
                                        {learn.foto25 && learn.foto26 && (
                                            <div className="project-page__photos__item wow fadeInUp new_sat_prof_nf">
                                                <div className="project-page__photos__item__two-photos">
                                                    <img src={`https://res.cloudinary.com/dbxcfrxhf/image/upload/f_auto,q_auto/${learn.foto25}`} alt="" />
                                                    <img src={`https://res.cloudinary.com/dbxcfrxhf/image/upload/f_auto,q_auto/${learn.foto26}`} alt="" />
                                                </div>
                                            </div>
                                        )}
                                        {learn.foto27 && learn.foto28 && (
                                            <div className="project-page__photos__item wow fadeInUp new_sat_prof_nf">
                                                <div className="project-page__photos__item__two-photos">
                                                    <img src={`https://res.cloudinary.com/dbxcfrxhf/image/upload/f_auto,q_auto/${learn.foto27}`} alt="" />
                                                    <img src={`https://res.cloudinary.com/dbxcfrxhf/image/upload/f_auto,q_auto/${learn.foto28}`} alt="" />
                                                </div>
                                            </div>
                                        )}
                                        {learn.foto29 && learn.foto30 && (
                                            <div className="project-page__photos__item wow fadeInUp new_sat_prof_nf">
                                                <div className="project-page__photos__item__two-photos">
                                                    <img src={`https://res.cloudinary.com/dbxcfrxhf/image/upload/f_auto,q_auto/${learn.foto29}`} alt="" />
                                                    <img src={`https://res.cloudinary.com/dbxcfrxhf/image/upload/f_auto,q_auto/${learn.foto30}`} alt="" />
                                                </div>
                                            </div>
                                        )}
                                         {learn.foto31 && learn.foto32 && (
                                            <div className="project-page__photos__item wow fadeInUp new_sat_prof_nf">
                                                <div className="project-page__photos__item__two-photos">
                                                    <img src={`https://res.cloudinary.com/dbxcfrxhf/image/upload/f_auto,q_auto/${learn.foto31}`} alt="" />
                                                    <img src={`https://res.cloudinary.com/dbxcfrxhf/image/upload/f_auto,q_auto/${learn.foto32}`} alt="" />
                                                </div>
                                            </div>
                                        )}
                                         {learn.foto33 && learn.foto34 && (
                                            <div className="project-page__photos__item wow fadeInUp new_sat_prof_nf">
                                                <div className="project-page__photos__item__two-photos">
                                                    <img src={`https://res.cloudinary.com/dbxcfrxhf/image/upload/f_auto,q_auto/${learn.foto33}`} alt="" />
                                                    <img src={`https://res.cloudinary.com/dbxcfrxhf/image/upload/f_auto,q_auto/${learn.foto34}`} alt="" />
                                                </div>
                                            </div>
                                        )}
                                         {learn.foto35 && learn.foto36 && (
                                            <div className="project-page__photos__item wow fadeInUp new_sat_prof_nf">
                                                <div className="project-page__photos__item__two-photos">
                                                    <img src={`https://res.cloudinary.com/dbxcfrxhf/image/upload/f_auto,q_auto/${learn.foto35}`} alt="" />
                                                    <img src={`https://res.cloudinary.com/dbxcfrxhf/image/upload/f_auto,q_auto/${learn.foto36}`} alt="" />
                                                </div>
                                            </div>
                                        )}
                                         {learn.foto37 && learn.foto38 && (
                                            <div className="project-page__photos__item wow fadeInUp new_sat_prof_nf">
                                                <div className="project-page__photos__item__two-photos">
                                                    <img src={`https://res.cloudinary.com/dbxcfrxhf/image/upload/f_auto,q_auto/${learn.foto37}`} alt="" />
                                                    <img src={`https://res.cloudinary.com/dbxcfrxhf/image/upload/f_auto,q_auto/${learn.foto38}`} alt="" />
                                                </div>
                                            </div>
                                        )}
                                         {learn.foto39 && learn.foto40 && (
                                            <div className="project-page__photos__item wow fadeInUp new_sat_prof_nf">
                                                <div className="project-page__photos__item__two-photos">
                                                    <img src={`https://res.cloudinary.com/dbxcfrxhf/image/upload/f_auto,q_auto/${learn.foto39}`} alt="" />
                                                    <img src={`https://res.cloudinary.com/dbxcfrxhf/image/upload/f_auto,q_auto/${learn.foto40}`} alt="" />
                                                </div>
                                            </div>
                                        )}
                                         {learn.foto41 && (
                                            <div className="project-page__photos__item wow fadeInUp new_sat_prof_nf">
                                                <div className="project-page__photos__item__two-photos">
                                                    <img src={`https://res.cloudinary.com/dbxcfrxhf/image/upload/f_auto,q_auto/${learn.foto41}`} alt="" />
                                        
                                                </div>
                                            </div>
                                        )}
                                       
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </>
    );
};


export default Learn;