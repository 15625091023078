import React from 'react';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer__bottom">
                <div className="flex align-end justify-between">
                    <div className="footer__bottom__left">
                        <ul className="social social--horizontal wow fadeInUp">
                            <li><a href="https://www.instagram.com/kaon.line/" title="Instagram" style={{ color: 'white', textDecoration: 'none' }} target="_blank" rel="noopener noreferrer">Instagram</a></li>
                            <li><a href="https://www.facebook.com/profile.php?id=100086786633592" title="Facebook" style={{ color: 'white', textDecoration: 'none' }} target="_blank" rel="noopener noreferrer">Facebook</a></li>
                            <li><a href="https://www.behance.net/kaonlinellc" title="Behance" style={{ color: 'white', textDecoration: 'none' }} target="_blank" rel="noopener noreferrer">Behance</a></li>
                            <li><a href="https://archello.com/brand/kaon-line" title="Archello" style={{ color: 'white', textDecoration: 'none' }} target="_blank" rel="noopener noreferrer">Archello</a></li>
                            <li><a href="https://www.linkedin.com/company/kaon-line/" title="Linkedin" style={{ color: 'white', textDecoration: 'none' }} target="_blank" rel="noopener noreferrer">Linkedin</a></li>

                        </ul>
                    </div>
                    <div className="footer__bottom__right wow fadeInUp">
                        <p>© KAON Line 2022</p>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
