import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const WorkProcess = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: <CustomPrevArrow />,
        nextArrow: <CustomNextArrow />
    };

    return (
        <section className="process" id="aboutus-section">
            <a id="anchor4" className="anchor"></a>
            <div className="process-border">
                <div className="col-md-info2 wow fadeInUp">
                    <h2 className="wow fadeInUp">
                        <font>About us</font>
                    </h2>
                    <Slider {...settings}>
                        <div>
                            <img
                                className="d-block w-100"
                                src="https://res.cloudinary.com/dbxcfrxhf/image/upload/f_auto,q_auto/about20"
                                alt="Photo 1"
                            />
                        </div>
                        <div>
                            <img
                                className="d-block w-100"
                                src="https://res.cloudinary.com/dbxcfrxhf/image/upload/f_auto,q_auto/about21"
                                alt="Photo 2"
                            />
                        </div>
                        <div>
                            <img
                                className="d-block w-100"
                                src="https://res.cloudinary.com/dbxcfrxhf/image/upload/f_auto,q_auto/about22"
                                alt="Photo 3"
                            />
                        </div>
                        <div>
                            <img
                                className="d-block w-100"
                                src="https://res.cloudinary.com/dbxcfrxhf/image/upload/f_auto,q_auto/about23"
                                alt="Photo 4"
                            />
                        </div>
                        <div>
                            <img
                                className="d-block w-100"
                                src="https://res.cloudinary.com/dbxcfrxhf/image/upload/f_auto,q_auto/about24"
                                alt="Photo 5"
                            />
                        </div>
                        <div>
                            <img
                                className="d-block w-100"
                                src="https://res.cloudinary.com/dbxcfrxhf/image/upload/f_auto,q_auto/about25"
                                alt="Photo 6"
                            />
                        </div>
                        <div>
                            <img
                                className="d-block w-100"
                                src="https://res.cloudinary.com/dbxcfrxhf/image/upload/f_auto,q_auto/about26"
                                alt="Photo 7"
                            />
                        </div>
                        <div>
                            <img
                                className="d-block w-100"
                                src="https://res.cloudinary.com/dbxcfrxhf/image/upload/f_auto,q_auto/about27"
                                alt="Photo 8"
                            />
                        </div>
                    </Slider>
                </div>
            </div>
        </section>
    );
};
const CustomPrevArrow = (props) => {
    const { className, onClick } = props;
    return (
        <div className={className} onClick={onClick}>
            <img src="https://res.cloudinary.com/dbxcfrxhf/image/upload/f_auto,q_auto/left" alt="Prev" />
        </div>
    );
};

// Custom next arrow component
const CustomNextArrow = (props) => {
    const { className, onClick } = props;
    return (
        <div className={className} onClick={onClick}>
            <img src="https://res.cloudinary.com/dbxcfrxhf/image/upload/f_auto,q_auto/right" alt="Next" />
        </div>
    );
};
export default WorkProcess;
