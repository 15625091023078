import $ from 'jquery';
import WOW from 'wowjs';

export function handleScrollAnimation() {
    var wow = new WOW.WOW(); // Create a new instance of WOW
    var count = 0;

    // Encapsulate the scrolling logic within a check for the existence of the .infographics element
    function animateOnScroll() {
        var infographicsElement = $('.infographics');
        if (!infographicsElement.length) return; // Exit if the element is not found

        var wScroll = $(window).scrollTop();

        if (wScroll > infographicsElement.offset().top - ($(window).height() / 1.2)) {
            if (count === 0) {
                $('.infographics__number').each(function () {
                    $(this).prop('Counter', 0).animate({
                        Counter: $(this).data('count')
                    }, {
                        duration: 2000,
                        easing: 'swing',
                        step: function (now) {
                            $(this).text(Math.ceil(now));
                        }
                    });
                });
                count = 1;
                wow.init(); // Initialize WOW after the condition is met
            }
        }
    }

    // Call animateOnScroll on initial load and scroll events
    $(window).on('load scroll', animateOnScroll);
}
