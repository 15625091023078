import React from 'react';

const Services = () => {
    return (
        <section className="services" id="services-section">
            <h2 className="w-color wow fadeInUp">
                Services
            </h2>

            {/* Design Section */}
            <div className="service-item wow fadeInUp" id="service-item">
                <div className="border">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="service-item__info">
                                <span className="service-item__title">Planning and Architecture</span>
                                <div className="show-hide-text">
                                    <p>
                                        Our architecture design projects offer a comprehensive solution for organizing space, consisting of the development of a concept for the future interior, selection of all finishing materials and furniture, as well as a full set of working drawings and 3D visualizations.

                                        Included services: Creation and planning according to requests and needs, preparation of technical specifications, room measurements, external design of buildings based on the circumstances where we are, of spaces in different locations, treatment of roaming spaces with interior architect. Our services are tailored to meet your specific requests and needs, ensuring a customized approach to every project. We specialize in creating external designs for buildings that harmonize with their surroundings, as well as optimizing spaces in diverse locations. Our expertise extends to the treatment of transitional spaces through the lens of interior architecture.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Planning Section */}
            <div className="service-item wow fadeInUp">
                <div className="border">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="service-item__info">
                                <span className="service-item__title">
                                    Interior Design & Architecture
                                </span>
                                <div className="show-hide-text">
                                    <p>
                                        Expertise in meticulously organizing spaces within administrative and residential facilities, as well as various other types of establishments. We offer specialized wall treatments tailored to the client's requests and needs, along with space accommodation and transformation based on specific requirements. Additionally, we provide technical expertise in electrical, hydraulic, ceiling, floor, and lighting systems.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="service-item wow fadeInUp" style={{ visibility: 'visible', animationName: 'fadeInUp' }}>
                <div className="border">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="service-item__info">
                                <span className="service-item__title">
                                    Interior Design & Decoration
                                </span>
                                <div className="show-hide-text">
                                    <p>
                                    We specialize in transforming existing spaces through innovative design solutions. Our expertise includes space planning, wall treatment, and custom furniture design to enhance the functionality and aesthetics of your environment.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="service-item wow fadeInUp">
                <div className="border">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="service-item__info">
                                <span className="service-item__title">
                                    Project Detailing
                                </span>
                                <div className="show-hide-text">
                                    <p>
                                    Our forte lies in meticulously outlining every step of the entry implementation process. This meticulous attention to detail is a hallmark of our expertise, showcasing our strongest skill set.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="service-item wow fadeInUp">
                <div className="border">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="service-item__info">
                                <span className="service-item__title">
                                    Selection of Versatile Elements 
                                </span>
                                <div className="show-hide-text">
                                    <p>
                                    Our services include the meticulous selection of flexible elements to enhance the adaptability and functionality of your space.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="service-item wow fadeInUp">
                <div className="border">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="service-item__info">
                                <span className="service-item__title">
                                    Comprehensive Project Implementation
                                </span>
                                <div className="show-hide-text">
                                    <p>
                                    Our dedicated team oversees every aspect of your project from inception to completion, ensuring a seamless and successful execution. With our expertise and attention to detail, we guide you through the entire process to bring your vision to life.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Services;
